/* General button style (reset) */
.g-btn {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	padding: 25px 80px;
	display: inline-block;
	margin: 15px 30px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.g-btn:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
/* Pseudo elements for icons */
.g-btn:before,
.icon-heart:after,
.icon-star:after,
.icon-plus:after,
.icon-file:before {
	font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.3.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-envelope:before {content: "\e000";}
.icon-cart:before {content: "\f07a";}
.icon-cart-2:before {content: "\e008";}
.icon-heart:before {content: "\e009";}
.icon-heart:after,
.icon-heart-2:before {content: "\e00a";}
.icon-star:before {content: "\e00b";}
.icon-star:after,
.icon-star-2:before {content: "\e00c";}
.icon-arrow-right:before {content: "\f061";}
.icon-arrow-left:before {content: "\f060";}
.icon-truck:before {content: "\e00e";}
.icon-remove:before {content: "\f1f8";}
.icon-cog:before {content: "\f013";}
.icon-plus:before,
.icon-plus:after {content: "\e011";}
.icon-minus:before {content: "\e012";}
.bh-icon-smiley:before {content: "\e001";}
.bh-icon-sad:before {content: "\e002";}
.icon-file:before {content: "\e004";}
.icon-remove-2:before {content: "\e005";}
.g-btn-1 {
	border: 3px solid #e74c3c;
	color: #e74c3c;
}
.g-btn-1a:hover,
.g-btn-1a:active {
	color: #fff;
	background: #e74c3c;
}
.g-btn-1b:after {
	width: 100%;
	height: 0;
	top: 0;
	left: 0;
	background: #e74c3c;
}
.g-btn-1b:hover,
.g-btn-1b:active {color: #fff;}
.g-btn-1b:hover:after,
.g-btn-1b:active:after{height: 100%;}
.g-btn-1c:after {
	width: 0%;
	height: 100%;
	top: 0;
	left: 0;
	background: #e74c3c;
}
.g-btn-1c:hover,
.g-btn-1c:active {color: #fff;}
.g-btn-1c:hover:after,
.g-btn-1c:active:after{width: 100%;}
.g-btn-1d {overflow: hidden;}
.g-btn-1d:after {
	width: 0;
	height: 103%;
	top: 50%;
	left: 50%;
	background: #e74c3c;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.g-btn-1d:hover,
.g-btn-1d:active {color: #fff;}
.g-btn-1d:hover:after {
	width: 90%;
	opacity: 1;
}
.g-btn-1d:active:after {
	width: 101%;
	opacity: 1;
}
.g-btn-1e {overflow: hidden;}
.g-btn-1e:after {
	width: 100%;
	height: 0;
	top: 50%;
	left: 50%;
	background: #e74c3c;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.g-btn-1e:hover,
.g-btn-1e:active {color: #fff;}
.g-btn-1e:hover:after {
	height: 260%;
	opacity: 1;
}
.g-btn-1e:active:after {
	height: 400%;
	opacity: 1;
}
.g-btn-1f {overflow: hidden;}
.g-btn-1f:after {
	width: 101%;
	height: 0;
	top: 50%;
	left: 50%;
	background: #e74c3c;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.g-btn-1f:hover,
.g-btn-1f:active{color: #fff;}
.g-btn-1f:hover:after {
	height: 75%;
	opacity: 1;
}
.g-btn-1f:active:after {
	height: 130%;
	opacity: 1;
}
.g-btn-2{
	background: #cb4e4e;
	color: #fff;
	box-shadow: 0 6px #ab3c3c;
	-webkit-transition: none;
	transition: none;
}
.g-btn-2a {border-radius: 0 0 5px 5px;}

.g-btn-2a:hover {
	box-shadow: 0 4px #ab3c3c;
	top: 2px;
}
.g-btn-2a:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2b {border-radius: 0 0 5px 5px;}
.g-btn-2b:hover {
	box-shadow: 0 8px #ab3c3c;
	top: -2px;
}
.g-btn-2b:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2c {border-radius: 5px;}
.g-btn-2c:hover {
	box-shadow: 0 4px #ab3c3c;
	top: 2px;
}
.g-btn-2c:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2d {	border-radius: 5px;}

.g-btn-2d:hover {
	box-shadow: 0 8px #ab3c3c;
	top: -2px;
}
.g-btn-2d:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2e {
	border-radius: 5px;
	box-shadow: -6px 0 #ab3c3c;
}
.g-btn-2e:hover {
	box-shadow: -4px 0 #ab3c3c;
	left: -2px;
}
.g-btn-2e:active {
	box-shadow: 0 0 #ab3c3c;
	left: -6px;
}
.g-btn-2f {
	border-radius: 5px;
	box-shadow: 6px 0 #ab3c3c;
}
.g-btn-2f:hover {
	box-shadow: 4px 0 #ab3c3c;
	left: 2px;
}
.g-btn-2f:active {
	box-shadow: 0 0 #ab3c3c;
	left: 6px;
}
.g-btn-2g {border-radius: 40px;}

.g-btn-2g:hover {
	box-shadow: 0 4px #ab3c3c;
	top: 2px;
}
.g-btn-2g:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2h {border-radius: 20px;}
.g-btn-2h:hover {
	box-shadow: 0 4px #ab3c3c;
	top: 2px;
}
.g-btn-2h:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2i {
	border-radius: 50%;
	width: 90px;
	height: 90px;
	padding: 0;
}
.g-btn-2i:hover{
	box-shadow: 0 4px #ab3c3c;
	top: 2px;
}
.g-btn-2i:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.g-btn-2j {
	border-radius: 50%;
	width: 90px;
	height: 90px;
	padding: 0;
}
.g-btn-2j:hover {
	box-shadow: 0 8px #ab3c3c;
	top: -2px;
}
.g-btn-2j:active {
	box-shadow: 0 0 #ab3c3c;
	top: 6px;
}
.color-4 {background: #2ecc71;}
.g-btn-3 {
	background: #fcad26;
	color: #fff;
}
.g-btn-3:hover {background: #f29e0d;}
.g-btn-3:active {
	background: #f58500;
	top: 2px;
}
.g-btn-3:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 170%;
	width: 60px;
	font-family: 'FontAwesome';
}
.g-btn-3a {padding: 25px 60px 25px 120px;}
.g-btn-3a:before {
	content: "\f07a";
	background: rgba(0,0,0,0.05);
}
.g-btn-3b {
	padding: 25px 60px 25px 120px;
	border-radius: 10px;
}
.g-btn-3b:before {
	content: "\f005";
	border-right: 2px solid rgba(255,255,255,0.5);
}
.g-btn-3c {
	padding: 80px 20px 20px 20px;
	border-radius: 10px;
	box-shadow: 0 3px #da9622;
}
.g-btn-3c:active{box-shadow: 0 3px #dc7801;}
.g-btn-3c:before {
	height: 60px;
	width: 100%;
	line-height: 60px;
	background: #fff;
	color: #f29e0d;
	content: "\f08a";
	border-radius: 10px 10px 0 0;
}
.g-btn-3c:active:before {color: #f58500;}
.g-btn-3d {
	padding: 25px 60px 25px 120px;
	border-radius: 10px;
}
.g-btn-3d:before {
	background: #fff;
	color: #fcad26;
	z-index: 2;
	border-radius: 10px 0 0 10px;
}
.g-btn-3d:after {
	width: 20px;
	height: 20px;
	background: #fff;
	z-index: 1;
	left: 55px;
	top: 50%;
	margin: -10px 0 0 -10px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.g-btn-3d:active:before {color: #f58500;}
.g-btn-3d:active {top: 0;}
.g-btn-3d:active:after {left: 60px;}
/* Button 3e */
.g-btn-3e {
	padding: 25px 120px 25px 60px;
	overflow: hidden;
}
.g-btn-3e:before {
	left: auto;
	right: 10px;
	z-index: 2;
}
.g-btn-3e:after {
	width: 30%;
	height: 200%;
	background: rgba(255,255,255,0.1);
	z-index: 1;
	right: 0;
	top: 0;
	margin: -5px 0 0 -5px;
	-webkit-transform-origin: 0 0;
	-webkit-transform: rotate(-20deg);
	-moz-transform-origin: 0 0;
	-moz-transform: rotate(-20deg);
	-ms-transform-origin: 0 0;
	-ms-transform: rotate(-20deg);
	-webkit-transform-origin: 0 0;
	    -ms-transform-origin: 0 0;
	        transform-origin: 0 0;
	-webkit-transform: rotate(-20deg);
	    -ms-transform: rotate(-20deg);
	        transform: rotate(-20deg);
}
.g-btn-3e:hover:after {width: 40%;}
.g-btn-4 {
	border-radius: 50px;
	border: 3px solid #e74c3c;
	color: #e74c3c;
	overflow: hidden;
}
.g-btn-4:active {
	border-color: #742c24;
	color: #fff;
}
.g-btn-4:hover {
	color: #fff;
	background: #e74c3c;
}
.g-btn-4:before {
	position: absolute;
	height: 100%;
	font-size: 165%;
	line-height: 3;
	color: #fff;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.g-btn-4:active:before {color: #fff;}
.g-btn-4a:before {
	left: 130%;
	top: 0;
}
.g-btn-4a:hover:before {left: 80%;}
.g-btn-4b:before {
	left: -50%;
	top: 0;
}
.g-btn-4b:hover:before {left: 10%;}
.g-btn-4c:before {
	left: 70%;
	opacity: 0;
	top: 0;
}

.g-btn-4c:hover:before {
	left: 80%;
	opacity: 1;
}
/* Button 4d */
.g-btn-4d:before {
	left: 30%;
	opacity: 0;
	top: 0;
}
.g-btn-4d:hover:before {
	left: 10%;
	opacity: 1;
}
/* Button 5 */
.g-btn-5{
	background: #823aa0;
	color: #fff;
	height: 70px;
	min-width: 260px;
	line-height: 24px;
	font-size: 16px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.g-btn-5:active {
	background: #9053a9;
	top: 2px;
}
.g-btn-5 span {
	display: inline-block;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	-webkit-transition: all 0.3s;
	        transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}
.g-btn-5:before {
	position: absolute;
	height: 100%;
	width: 100%;
	line-height: 2.5;
	font-size: 180%;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.g-btn-5:active:before {color: #703b87;}
/* Button 5a */
.g-btn-5a:hover span {
	-webkit-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}
.g-btn-5a:before {
	left: 0;
	top: -100%;
}
.g-btn-5a:hover:before {top: 0;}
/* Button 5b */
.g-btn-5b:hover span {
	-webkit-transform: translateX(200%);
	-ms-transform: translateX(200%);
	transform: translateX(200%);
}
.g-btn-5b:before {
	left: -100%;
	top: 0;
}
.g-btn-5b:hover:before {left: 0;}
/* Button 6 */
.g-btn-6 {
	color: #fff;
	background: #226fbe;
	-webkit-transition: none;
	transition: none;
}
.g-btn-6:active {top: 2px;}
/* Button 6a */
.g-btn-6a {border: 4px solid #226fbe;}
.g-btn-6a:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6b */
.g-btn-6b {
	border: 4px solid #226fbe;
	border-radius: 15px;
}
.g-btn-6b:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6c */
.g-btn-6c {
	border: 4px solid #226fbe;
	border-radius: 60px;
}
.g-btn-6c:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6d */
.g-btn-6d {border: 2px dashed #226fbe;}
.g-btn-6d:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6e */
.g-btn-6e {
	border: 2px dashed #226fbe;
	border-radius: 15px;
}
.g-btn-6e:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6f */
.g-btn-6f {
	border: 2px dashed #226fbe;
	border-radius: 60px;
}
.g-btn-6f:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6g */
.g-btn-6g {border: 2px dotted #226fbe;}
.g-btn-6g:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6h */
.g-btn-6h {
	border: 2px dotted #226fbe;
	border-radius: 15px;
}
.g-btn-6h:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6i */
.g-btn-6i {
	border: 2px dotted #226fbe;
	border-radius: 60px;
}
.g-btn-6i:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6j */
.g-btn-6j {border: 4px double #226fbe;}
.g-btn-6j:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6k */
.g-btn-6k {
	border: 4px double #226fbe;
	border-radius: 15px;
}
.g-btn-6k:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 6l */
.g-btn-6l {
	border: 4px double #226fbe;
	border-radius: 60px;
}
.g-btn-6l:hover {
	background: transparent;
	color: #226fbe;
}
/* Button 7 */
.g-btn-7 {
	background: #17aa56;
	color: #fff;
	border-radius: 7px;
	box-shadow: 0 5px #119e4d;
	padding: 25px 60px 25px 90px;
}
/* Button 7a */
.g-btn-7a {overflow: hidden;}
.g-btn-7a:before {
	position: absolute;
	left: 0;
	width: 40%;
	font-size: 160%;
	line-height: 0.8;
	color: #0a833d;
}
.g-btn-7a.btn-activated {
	-webkit-animation: fadeOutText 0.5s;
	animation: fadeOutText 0.5s;
}
.g-btn-7a.btn-activated:before {
	-webkit-animation: moveToRight 0.5s;
	animation: moveToRight 0.5s;
}
@-webkit-keyframes fadeOutText {
	0% { color: transparent; }
	80% { color: transparent; }
	100% { color: #fff; }
}
@keyframes fadeOutText {
	0% { color: transparent; }
	80% { color: transparent; }
	100% { color: #fff; }
}
@-webkit-keyframes moveToRight {
	80% { -webkit-transform: translateX(250%); }
	81% { opacity: 1; -webkit-transform: translateX(250%); }
	82% { opacity: 0; -webkit-transform: translateX(250%); }
	83% { opacity: 0; -webkit-transform: translateX(-50%); }
	84% { opacity: 1; -webkit-transform: translateX(-50%);  }
	100% { -webkit-transform: translateX(0%); }
}
@keyframes moveToRight {
	80% { -webkit-transform: translateX(250%); transform: translateX(250%); }
	81% { opacity: 1; -webkit-transform: translateX(250%); transform: translateX(250%); }
	82% { opacity: 0; -webkit-transform: translateX(250%); transform: translateX(250%); }
	83% { opacity: 0; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
	84% { opacity: 1; -webkit-transform: translateX(-50%); transform: translateX(-50%);  }
	100% { -webkit-transform: translateX(0%); transform: translateX(0%); }
}
/* Button 7b */
.g-btn-7b {overflow: hidden;}
.g-btn-7b:before {
	position: absolute;
	left: 0;
	width: 40%;
	font-size: 160%;
	line-height: 0.8;
	color: #0a833d;
}
.g-btn-7b.btn-activated:before {
	-webkit-animation: scaleUp 0.5s;
	animation: scaleUp 0.5s;
}
@-webkit-keyframes scaleUp {
	80% {
		opacity: 0;
		-webkit-transform: scale(2);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
	}
}
@keyframes scaleUp {
	80% {
		opacity: 0;
		-webkit-transform: scale(2);
		        transform: scale(2);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
		        transform: scale(2);
	}
}
/* Icon only style */
.g-btn-icon-only {
	font-size: 0;
	padding: 25px 30px;
}
.g-btn-icon-only:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 26px;
	line-height: 54px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
/* Button 7c */
.g-btn-7c {overflow: hidden;}
.g-btn-7c:before {
	color: #fff;
	z-index: 1;
}
.g-btn-7c:after {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 0;
	width: 0;
	background: #0a833d;
	-webkit-transition: none;
	transition: none;
}
.g-btn-7c.btn-activated:after {
	-webkit-animation: fillToRight 0.7s forwards;
	animation: fillToRight 0.7s forwards;
}

@-webkit-keyframes fillToRight {
	to {width: 100%;}
}

@keyframes fillToRight {
	to {width: 100%;}
}
/* Button 7d */
.g-btn-7d {overflow: hidden;}
.g-btn-7d:before {
	color: #fff;
	z-index: 1;
}
.g-btn-7d:after {
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 100%;
	z-index: 0;
	background: #21bb63;
	-webkit-transition: none;
	transition: none;
}
.g-btn-7d.btn-activated:after {
	-webkit-animation: emptyBottom 0.7s forwards;
	animation: emptyBottom 0.7s forwards;
}
@-webkit-keyframes emptyBottom {
	to {height: 100%;}
}
@keyframes emptyBottom {
	to {height: 100%;}
}
/* Button 7e */
.g-btn-7e:after {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	font-size: 26px;
	line-height: 54px;
	color: #ea515e;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	opacity: 0;
	-webkit-transition: none;
	transition: none;
}
.g-btn-7e.btn-activated:after {
	-webkit-animation: scaleFade 0.5s forwards;
	animation: scaleFade 0.5s forwards;
} 
@-webkit-keyframes scaleFade {
	50% { 
		opacity: 1;
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2.5);
	}
}
@keyframes scaleFade {
	50% { 
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2.5);
		        transform: scale(2.5);
	}
}
/* Button 7f */
.g-btn-7f:before {
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #fff;
}
.g-btn-7f:after {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	font-size: 26px;
	line-height: 54px;
	color: #ffe44d;
	visibility: hidden;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #ffe44d;
	-webkit-transform: scale(4);
	-ms-transform: scale(4);
	transform: scale(4);
	opacity: 0;
	-webkit-transition: none;
	transition: none;
}
.g-btn-7f.btn-activated:after {
	visibility: visible;
	-webkit-animation: dropDown 0.3s forwards;
	animation: dropDown 0.3s forwards;
} 
@-webkit-keyframes dropDown {
	to { 
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@keyframes dropDown {
	to { 
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
/* Button 7g */
.g-btn-7g:after {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	font-size: 26px;
	line-height: 54px;
	color: #0a833d;
	visibility: hidden;
	-webkit-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
	opacity: 0;
	-webkit-transition: none;
	transition: none;
}
.g-btn-7g.btn-activated:after {
	visibility: visible;
	-webkit-animation: dropDownFade 0.5s forwards;
	animation: dropDownFade 0.5s forwards;
} 
@-webkit-keyframes dropDownFade {
	50% { 
		opacity: 1;
		-webkit-transform: scale(1);
	}
	100% { 
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}
@keyframes dropDownFade {
	50% { 
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% { 
		opacity: 0;
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
	}
}
/* Button 7h */
.g-btn-7h span {
	display: inline-block;
	width: 100%;
}

.g-btn-7h:before {
	position: absolute;
	left: 0;
	width: 40%;
	font-size: 160%;
	line-height: 0.8;
	color: #0a833d;
}
/* Success and error */
.g-btn-success,
.g-btn-error {
	color: transparent;
}
.g-btn-success:after,
.g-btn-error:after {
	z-index: 1;
	color: #fff;
	left: 40%;
}
.g-btn-success:before {content: "\e001";}
.g-btn-success:after {
	content: "Success!";
	-webkit-animation: moveUp 0.5s;
	animation: moveUp 0.5s;
}
@-webkit-keyframes moveUp {
	0% {
		-webkit-transform: translateY(50%);
		opacity: 0;
	}
	100% { 
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}
@keyframes moveUp {
	0% {
		-webkit-transform: translateY(50%);
		        transform: translateY(50%);
		opacity: 0;
	}
	100% { 
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}
.g-btn-error {
	-webkit-animation: shake 0.5s;
	animation: shake 0.5s;
}
/* From Dan Eden's animate.css: http://daneden.me/animate/ */
@-webkit-keyframes shake {
	0%, 100% {-webkit-transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);}
	20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);}
}

@keyframes shake {
	0%, 100% {-webkit-transform: translateX(0);transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);transform: translateX(-10px);}
	20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);transform: translateX(10px);}
}
.g-btn-error:before {content: "\e002";}
.g-btn-error:after {
	content: "Error!";
	-webkit-animation: scaleFromUp 0.5s;
	animation: scaleFromUp 0.5s;
}
@-webkit-keyframes scaleFromUp {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	100% { 
		opacity: 1;
		-webkit-transform: scale(1);
	}
}
@keyframes scaleFromUp {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
		opacity: 0;
	}
	100% { 
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
/* Special trash effect */
.g-trash-effect {
	position: relative;
	max-width: 320px;
	margin: 60px auto 0;
} 
.g-trash-effect .icon-file {
	font-size: 30px;
	position: absolute;
	width: 50px;
	height: 50px;
	left: 50%;
	top: 50%;
	margin: -45px 0 0 -25px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	-webkit-transform: translateX(90px) translateY(20px) scale(1);
	-ms-transform: translateX(90px) translateY(20px) scale(1);
	transform: translateX(90px) translateY(20px) scale(1);
}
.g-trash-effect .icon-file:nth-child(2) {
	-webkit-transform: translateX(140px) translateY(-10px) scale(1);
	-ms-transform: translateX(140px) translateY(-10px) scale(1);
	transform: translateX(140px) translateY(-10px) scale(1);
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.g-trash-effect .icon-file:nth-child(3) {
	-webkit-transform: translateX(140px) translateY(50px) scale(1);
	-ms-transform: translateX(140px) translateY(50px) scale(1);
	transform: translateX(140px) translateY(50px) scale(1);
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.g-trash-effect.trash-effect-active .icon-file {
	-webkit-transform: translateX(-100px) translateY(-10px) scale(0);
	-ms-transform: translateX(-100px) translateY(-10px) scale(0);
	transform: translateX(-100px) translateY(-10px) scale(0);
}
/* Button 7i */
.g-btn-7i {
	box-shadow: none;
	border-radius: 0 0 7px 7px;
	padding: 27px 33px;
	-webkit-transform: translateX(-100px);
	-ms-transform: translateX(-100px);
	transform: translateX(-100px);
}
.g-btn-7i:before {line-height: 45px;}
.g-btn-7i:after {
	height: 11px; /* should be 10px but in Chrome Version 34.0.1847.131 there's a gap because of the transform of the button */
	width: 100%;
	background: #17aa56;
	border-radius: 7px 7px 0 0;
	left: 0;
	top: -10px;
	z-index: 1;
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
}
.g-trash-effect-active .g-btn-7i:after {
	-webkit-animation: openTrash 0.5s;
	animation: openTrash 0.5s;
}
@-webkit-keyframes openTrash {
	50% {
		-webkit-transform: rotate(-35deg);
	}
	100% { 
		-webkit-transform: rotate(0deg);
	}
}
@keyframes openTrash {
	50% {
		-webkit-transform: rotate(-35deg);
		        transform: rotate(-35deg);
	}
	100% { 
		-webkit-transform: rotate(0deg); 
		        transform: rotate(0deg);
	}
}
/* Button 8 */
.g-perspective {
	-webkit-perspective: 800px;
	perspective: 800px;
	display: inline-block;
}
.g-btn-8 {
	display: block;
	background: #5cbcf6;
	outline: 1px solid transparent; /* FF jagged edges fix */
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.g-btn-8:active {background: #55b7f3;}
/* Button 8a */
.g-btn-8a:after {
	width: 100%;
	height: 42%; /* should be 40% but there's a gap in Chrome Version 34.0.1847.131 */
	left: 0;
	top: -40%;
	background: #49a7df;
	-webkit-transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	-moz-transform-origin: 0% 100%;
	-moz-transform: rotateX(90deg);
	-webkit-transform-origin: 0% 100%;
	    -ms-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	        transform: rotateX(90deg);
}
.g-btn-8a:hover {
	-webkit-transform: rotateX(-15deg);
	-ms-transform: rotateX(-15deg);
	transform: rotateX(-15deg);
}
/* Button 8b */
.g-btn-8b:after {
	width: 100%;
	height: 40%;
	left: 0;
	top: 100%;
	background: #49a7df;
	-webkit-transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	-moz-transform-origin: 0% 0%;
	-moz-transform: rotateX(-90deg);
	-ms-transform-origin: 0% 0%;
	-ms-transform: rotateX(-90deg);
	-webkit-transform-origin: 0% 0%;
	    -ms-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	        transform: rotateX(-90deg);
}
.g-btn-8b:hover {
	-webkit-transform: rotateX(15deg);
	-ms-transform: rotateX(15deg);
	transform: rotateX(15deg);
}
/* Button 8c */
.g-btn-8c:after {
	width: 21%; /* should be 20% but there's a gap in Chrome Version 34.0.1847.131 */
	height: 100%;
	left: -20%;
	top: 0;
	background: #49a7df;
	-webkit-transform-origin: 100% 0%;
	-webkit-transform: rotateY(-90deg);
	-moz-transform-origin: 100% 0%;
	-moz-transform: rotateY(-90deg);
	-ms-transform-origin: 100% 0%;
	-ms-transform: rotateY(-90deg);
	-webkit-transform-origin: 100% 0%;
	    -ms-transform-origin: 100% 0%;
	        transform-origin: 100% 0%;
	-webkit-transform: rotateY(-90deg);
	        transform: rotateY(-90deg);
}
.g-btn-8c:hover {
	-webkit-transform: rotateY(15deg);
	-ms-transform: rotateY(15deg);
	transform: rotateY(15deg);
}
/* Button 8d */
.g-btn-8d:after {
	width: 20%;
	height: 100%;
	left: 100%;
	top: 0;
	background: #49a7df;
	-webkit-transform-origin: 0% 0%;
	-webkit-transform: rotateY(90deg);
	-moz-transform-origin: 0% 0%;
	-moz-transform: rotateY(90deg);
	-ms-transform-origin: 0% 0%;
	-ms-transform: rotateY(90deg);
	-webkit-transform-origin: 0% 0%;
	    -ms-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	-webkit-transform: rotateY(90deg);
	        transform: rotateY(90deg);
}
.g-btn-8d:hover {
	-webkit-transform: rotateY(-15deg);
	-ms-transform: rotateY(-15deg);
	transform: rotateY(-15deg);
}
/* Button 8e */
.g-btn-8e {
	-webkit-transform: rotateX(-15deg);
	-ms-transform: rotateX(-15deg);
	transform: rotateX(-15deg);
}
.g-btn-8e:after {
	width: 100%;
	height: 42%; /* should be 40% but there's a gap in Chrome Version 34.0.1847.131 */
	left: 0;
	top: -40%;
	background: #49a7df;
	-webkit-transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	-moz-transform-origin: 0% 100%;
	-moz-transform: rotateX(90deg);
	-ms-transform-origin: 0% 100%;
	-ms-transform: rotateX(90deg);
	-webkit-transform-origin: 0% 100%;
	    -ms-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	        transform: rotateX(90deg);
}
.g-btn-8e:active {
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	transform: rotateX(0deg);
}
/* Button 8f */
.g-btn-8f {
	-webkit-transform: rotateX(15deg);
	-ms-transform: rotateX(15deg);
	transform: rotateX(15deg);
}
.g-btn-8f:after {
	width: 100%;
	height: 40%;
	left: 0;
	top: 100%;
	background: #49a7df;
	-webkit-transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	-moz-transform-origin: 0% 0%;
	-moz-transform: rotateX(-90deg);
	-ms-transform-origin: 0% 0%;
	-ms-transform: rotateX(-90deg);
	-webkit-transform-origin: 0% 0%;
	    -ms-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	        transform: rotateX(-90deg);
}
.g-btn-8f:active {
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	transform: rotateX(0deg);
}
/* Button 8g */
.g-btn-8g {
	background: #fff;
	color: #999;
}
.g-btn-8g:active {background: #fff;}
.g-btn-8g:after,
.g-btn-8g:before {
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	line-height: 70px;
}
.g-btn-8g:after {
	top: -98%; /* should be -100% but there's a gap in Chrome Version 34.0.1847.131 */
	background: #7aca7c;
	color: #358337;
	content: 'It worked!';
	-webkit-transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	-moz-transform-origin: 0% 100%;
	-moz-transform: rotateX(90deg);
	-ms-transform-origin: 0% 100%;
	-ms-transform: rotateX(90deg);
	-webkit-transform-origin: 0% 100%;
	    -ms-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
	-webkit-transform: rotateX(90deg);
	        transform: rotateX(90deg);
}
.g-btn-8g:before {
	top: 100%;
	background: #e96a6a;
	color: #a33a3a;
	content: 'Error!';
	font-weight: 700;
	font-family: 'Lato', Calibri, Arial, sans-serif;
	-webkit-transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	-moz-transform-origin: 0% 0%;
	-moz-transform: rotateX(-90deg);
	-ms-transform-origin: 0% 0%;
	-ms-transform: rotateX(-90deg);
	-webkit-transform-origin: 0% 0%;
	    -ms-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	-webkit-transform: rotateX(-90deg);
	        transform: rotateX(-90deg);
}
.g-btn-8g.btn-success3d {
	background: #aaa;
	-webkit-transform-origin: 50% 100%;
	-webkit-transform: rotateX(-90deg) translateY(100%);
	-moz-transform-origin: 50% 100%;
	-moz-transform: rotateX(-90deg) translateY(100%);
	-ms-transform-origin: 50% 100%;
	-ms-transform: rotateX(-90deg) translateY(100%);
	-webkit-transform-origin: 50% 100%;
	    -ms-transform-origin: 50% 100%;
	        transform-origin: 50% 100%;
	-webkit-transform: rotateX(-90deg) translateY(100%);
	        transform: rotateX(-90deg) translateY(100%);
}

.g-btn-8g.btn-error3d  {
	background: #aaa;
	-webkit-transform-origin: 50% 0%;
	-webkit-transform: rotateX(90deg) translateY(-100%);
	-moz-transform-origin: 50% 0%;
	-moz-transform: rotateX(90deg) translateY(-100%);
	-ms-transform-origin: 50% 0%;
	-ms-transform: rotateX(90deg) translateY(-100%);
	-webkit-transform-origin: 50% 0%;
	    -ms-transform-origin: 50% 0%;
	        transform-origin: 50% 0%;
	-webkit-transform: rotateX(90deg) translateY(-100%);
	        transform: rotateX(90deg) translateY(-100%);
}
